import env from '../utils/env';
import PageScrollingManager from './PageScrollingManager';
import DelayedCall from '../helpers/DelayedCall';

class AppearParent {
    constructor() {
        this.appearTargets = [];
    }

    start() {
        this.setupAppearElements();
    }

    clear() {
        this.appearTargets.forEach((target) => {
            PageScrollingManager.unwatch(target);
        });
    }

    setupAppearElements() {
        const appearAttrName = 'data-appear-parent';
        const appearElements = document.querySelectorAll(`[${appearAttrName}]`);

        for (const appearElement of appearElements) {
            const [delay = 0, delayBetween = 70, childSelector = '.appear-child', addClass = '_visible'] = appearElement.getAttribute(appearAttrName).split(',').map(attr => attr.trim());

            const appearObserver = new IntersectionObserver(([entry]) => {
                const { isIntersecting, target } = entry;

                if (isIntersecting) {
                    target.querySelectorAll(childSelector).forEach((child, i) => {
                        const delayedCallDelay = delay + delayBetween * i;

                        DelayedCall.create(delayedCallDelay, () => {
                            child.classList.add(addClass);
                        });
                    });

                    appearObserver.unobserve(target);
                }
            });

            this.appearTargets.push(appearElement);
            PageScrollingManager.watch(appearElement);
            appearObserver.observe(appearElement);
        }
    }
}

export default new AppearParent();
