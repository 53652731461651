// Project imports
import env from '@js/utils/env';
import PageScrollingManager from '@js/modules/PageScrollingManager';
import DelayedCall from '@js/helpers/DelayedCall';

// Imports
import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText.js";

// Register GSAP plugin
gsap.registerPlugin(SplitText);

class SplitTextAnimation {
    constructor() {
        this.splitTextTargets = [];
    }
    start() {
        this._setup();
    }
    clear() {
        this.splitTextTargets.forEach((target) => {
			PageScrollingManager.unwatch(target);
		});
    }
    _setup() {
        const attrName = 'data-split-text';

        // Split text elements
		const splitTextElements = document.querySelectorAll('[' + attrName + ']');

        // Callback for IntersectionObserver
        const splitTextCallback = function(entries) {
            entries.forEach(entry => {
                const attributeValues = entry.target
    				.getAttribute(attrName)
    				.split(',');

                // Get the default delay duration
                const delay = Number.parseFloat(attributeValues[0] || 0);
                // Get the mobile delay duration
                const delayMobile = Number.parseFloat(attributeValues[1] || 0);
                // Get the class to add to the element
    			const addClass = attributeValues[2] || '_visible';

                // Is the element in the viewport?
                if (entry.isIntersecting) {
                    let delayedCallDelay = delay;

                    // If mobile value has been pased and it is a mobile device get mobile delay
                    if (delayMobile > 0 && env.isMobile) {
                        delayedCallDelay = delayMobile;
                    }

                    DelayedCall.create(delayedCallDelay, () => {
						entry.target.classList.add(addClass);

                        let tl = gsap.timeline(),
                            elSplitText = new SplitText(entry.target, {
                                type: 'words,chars',
                                linesClass: 'split-line'
                            }),
                            chars = elSplitText.chars;

                        // Show element
                        gsap.to(entry.target, {
                            duration: 0,
                            alpha: 1
                        });

                        // Animate characters
                        tl.from(chars, {
                            duration: .6,
                            y: '100%',
                            ease: 'circ.out',
                            stagger: 0.03
                        });

                        splitTextObserver.unobserve(entry.target);
					});
                }
            });
        };

        const splitTextObserver = new IntersectionObserver(splitTextCallback);
		splitTextElements.forEach((target) => {
            // Add the element to the watcher
            splitTextObserver.observe(target);
		});
    }
}

export default new SplitTextAnimation();
