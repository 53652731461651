/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import VhCalc from '@js/helpers/ViewHeightCalc';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import GridTitle from '@js/modules/GridTitle';
import Navigation from '@js/modules/Navigation';
import ScrollTo from '@js/modules/ScrollTo';
import SectionStyles from '@js/modules/SectionStyles';
import ScrollTopBtn from '@js/modules/ScrollTopBtn';
import SplitTextAnimation from '@js/modules/SplitTextAnimation';

// Imports
import LazyLoad from 'vanilla-lazyload';
import Cookies from 'js-cookie';
import reframe from 'reframe.js';


window.TMRRW =
    window.TMRRW ||
    new (function TMRRW() {
        // Utilities
        this.env = env;
        this.utils = utils;

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            VhCalc: VhCalc,
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Appear: Appear,
            AppearParent: AppearParent,
            GridTitle: GridTitle,
            Navigation: Navigation,
            ScrollTo: ScrollTo,
            SectionStyles: SectionStyles,
            ScrollTopBtn: ScrollTopBtn,
            SplitTextAnimation: SplitTextAnimation,
        };

        // If the user does not have reduced motion settings in the browser
        if (!this.env.reducedMotion) {
            this.modules.Appear.start();
            this.modules.AppearParent.start();
            this.modules.SplitTextAnimation.start();
        }

        this.modules.PageScrolling.start();

        // Window Load
        window.addEventListener('load', () => {
            this.modules.PageScrollingManager.update(true);
            this.modules.SectionStyles.update(true);
        });

        // DOM load
        document.addEventListener('DOMContentLoaded', () => {

            // Start section styles
            this.modules.SectionStyles.start();

            /**
             * Pause autoplay if reduced motion is selected
             */
            if (this.env.reducedMotion) {
                // Pause any autoplay videos
                const autoPlayVideos = document.querySelectorAll('video[autoplay]');
                autoPlayVideos.forEach((video) => {
                    video.pause();
                });
            }

            /**
             * Lazy load
             */
            let lazyLoadInstance = new LazyLoad({
                threshold: 400
            });

            /**
             * Reframe
             */
            reframe('.reframe');

            /**
             * Current year
             */
            // Return today's date and time
            const currentTime = new Date();
            // Returns the year (four digits)
            const currentYear = currentTime.getFullYear();

            // Output year to all elements
            const currentYearEls = document.querySelectorAll('.current-year');
            currentYearEls.forEach(el => {
                el.innerHTML = currentYear;
            });

            /**
             * Cookie message
             */
            const cookieMessageEl = document.getElementById('cookie-message');
            const cookieMessageClose = document.getElementById('cookie-accept');
            const cookieMessageName = '__cookie-message';

            // Test for cookie message element
            if (cookieMessageEl && !Cookies.get(cookieMessageName)) {
                // Show cookie message if the cookie is not detected
                cookieMessageEl.classList.remove('hidden');

                // Set cookie
                Cookies.set(cookieMessageName, '1', { expires: 365 });

                // Cookie message close
                cookieMessageClose.addEventListener('click', (e) => {
                    e.preventDefault();
                    cookieMessageEl.remove();
                });
            }
            else {
                if (cookieMessageEl) {
                    cookieMessageEl.remove();
                }
            }

            /**
             * Play video on hover
             */
            const playOnHoverEls = document.querySelectorAll('.play-on-hover');

            playOnHoverEls.forEach((item) => {
                let video = item.querySelector('video');
                console.log(video);

                if (video) {
                    item.addEventListener('mouseover', () => {
                        video.play();
                    });

                    item.addEventListener('mouseout', () => {
                        video.pause();
                    });
                }
            });


        });
    })();
