import PageScrolling from '@js/modules/PageScrolling';

class ScrollTopBtn {
    constructor() {
        this.showing = false;
        this.scrollAmount = 300;

        const scrollTopBtn = document.querySelectorAll('.scroll-top-btn');

        const toggleScrollTop = () => {
            if (PageScrolling.scrollTop > this.scrollAmount) {
                if (this.showing)
                    return;

                this.showing = true;

                scrollTopBtn.forEach((item) => {
                    // Main nav
                    if (item.classList.contains('_in-main-nav')) {
                        gsap.to(
                            item,
                            {
                                duration: this.reducedMotion ? 0 : .4,
                                display: 'block',
                                alpha: 1
                            }
                        );
                    }

                    // Mobile nav
                    if (item.classList.contains('_in-mobile-nav')) {
                        item.classList.remove('!hidden');
                    }
                });
            }
            else {
                if (!this.showing)
                    return;

                this.showing = false;

                scrollTopBtn.forEach((item) => {
                    // Main nav
                    if (item.classList.contains('_in-main-nav')) {
                        gsap.to(
                            item,
                            {
                                duration: this.reducedMotion ? 0 : .4,
                                display: 'none',
                                alpha: 0
                            }
                        );
                    }

                    // Mobile nav
                    if (item.classList.contains('_in-mobile-nav')) {
                        item.classList.add('!hidden');
                    }
                });
            }
        };

        window.addEventListener('resize', () => {
            toggleScrollTop();
        });

        PageScrolling.onScroll.add(
            () => {
                toggleScrollTop();
            }
        );
    }
}

export default new ScrollTopBtn();
