class GridTitle {
	constructor() {
        let gridTitleEls = document.querySelectorAll('.grid-title');

        gridTitleEls.forEach((element) => {
            this.calulate(element, true);

            // We listen to the resize event
            window.addEventListener('resize', () => {
                this.calulate(element, false);
            });
        });
	}
    calulate(element, first) {
        if (element) {
            // Get the first text row element
            // This will be used to calculate the height of the rows
            let rowEl = element.querySelector('.grid-title-text');
            // Get information about the size of the element
            let bcr = rowEl.getBoundingClientRect();

            // Get the title of the element
            let name = element.getAttribute('data-grid-title').toLowerCase();

            // Set the value in the custom variable to the root of the document
            document.documentElement.style.setProperty('--gt-row-height-'+name, `${bcr.height - 6}px`);

            // If first time being calculated, show images
            if (first) {
                let images = element.querySelectorAll('.grid-title-asset-img');

                images.forEach((el) => {
                    el.style.setProperty('opacity', 1);
                });
            }
        }
    }
}

export default new GridTitle();
