// Project imports
import PageScrolling from '@js/modules/PageScrolling';

//  Imports
import { gsap } from 'gsap';

const HTML_CLASSLIST = document.documentElement.classList;
const CSS_OPENED = '_nav-open';
const NAV_BREAKPOINT = 768;

class Navigation {
    constructor() {
        this.opened = false;

        this.element = document.querySelector('.mobile-nav');
        this.navBtns = this.element.querySelectorAll('nav li');

        this.opener = document.getElementById('nav-btn');
        this.opener.addEventListener('click', (e) => {
            e.preventDefault();

            this.toggle();
        });

        window.addEventListener('keydown', (e) => {
            if (this.opened && e.keyCode == 27) {
                this.close();
            }
        });

        // Close the mobile nav if the browser window is larger than the nav breakpoint
        // This is where the header navigation will show
        // NOTE: The CSS breakpoint is controlled in the tailwind config
        window.addEventListener('resize', e => {
            if (window.innerWidth >= NAV_BREAKPOINT) {
                this.close();
            }
		});
    }
    open() {
        if (!this.opened) {
            this.opened = true;

            // Update accessability tags
            this.opener.setAttribute('aria-expanded', true);

            HTML_CLASSLIST.add(CSS_OPENED);

            PageScrolling.lock(this.element);

            // Animate nav
            gsap.to(
                this.element,
                {
                    duration: this.reducedMotion ? 0 : .4,
                    y: 0,
                    onComplete: () => {
                        let btnDelay = 0;

                        // Animate buttons
                        this.navBtns.forEach((btn, index) => {
                            gsap.to(
                                btn,
                                {
                                    alpha: 1,
                                    y: 0,
                                    duration: this.reducedMotion ? 0 : .5,
                                    delay: this.reducedMotion ? 0 : btnDelay
                                }
                            );

                            btnDelay = btnDelay + .09;
                        });
                    }
                }
            );
        }
    }
    close() {
        if (this.opened) {
            this.opened = false;

            // Update accessability tags
            this.opener.setAttribute('aria-expanded', false);

            HTML_CLASSLIST.remove(CSS_OPENED);

            // Animate nav
            gsap.to(
                this.element,
                {
                    duration: this.reducedMotion ? 0 : .4,
                    y: '-100%',
                    onComplete: () => {
                        PageScrolling.unlock(this.element);

                        // Animate buttons
                        this.navBtns.forEach((btn, index) => {
                            gsap.to(
                                this.navBtns,
                                {
                                    duration: 0,
                                    alpha: 0,
                                    y: '-20px'
                                }
                            );
                        });
                    }
                }
            );
        }
    }
    toggle() {
        if (this.opened) {
            this.close();
        }
        else {
            this.open();
        }
    }
}

export default new Navigation();
